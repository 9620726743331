<template>
  <v-container fluid fill-height>
    <v-layout align-start>
      <v-flex>
        <!-- Modal persona -->
        <v-dialog v-model="verPersona" max-width="700px" persistent>
          <v-card>
            <v-toolbar dark dense class="dgreen" flat>
              <v-toolbar-title>Nuevo Cliente</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="guardarPersona" :loading="desabilitarPer" :disabled="desabilitarPer || !isValidPer">
                <v-icon>save</v-icon>
              </v-btn>
              <v-btn icon @click="closePersona">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="isValidPer">
                <v-row class="mt-2">
                  <v-col cols="6" sm="3">
                    <v-select dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli" label="Tipo Documento"
                      :rules="[v => !!v || 'Tipo documento requerido']" @change="num_documento = ''" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="10" sm="5">
                    <v-text-field dense v-model.trim="num_documento" label="Numero Documento"
                      :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                      :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                      :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                      @keypress="common.isNum($event)" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <div class="text-center">
                      <v-btn x-small fab color="primary" class="hide-detailswhite--text" :loading="desabilitarBuscar"
                        :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                        @click="extraerDocumento(num_documento)">
                        <v-icon>search</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="nombre"
                      :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                      :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 1 && v.length <= 100) || 'Nombre debe tener entre 1 a 100 caracteres', Rules.unespacio]"
                      maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo" v-model="idubigeo"
                      :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                      prepend-inner-icon="pin_drop" clearable class="mr-1"></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="direccion" label="Direccion"
                      :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres', Rules.unespacio]"
                      maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="email" label="Email"
                      :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                      maxlength="60" prepend-inner-icon="mail" v-lowercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="telefono" label="Telefono"
                      :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                      maxlength="30" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal persona -->

        <!-- Producto -->
        <v-dialog v-model="verProductos" max-width="1600px">
          <v-card>
            <v-toolbar dark dense class="dgreen mb-1" flat>
              <v-toolbar-title class="hidden-sm-and-down">Productos</v-toolbar-title>
              <v-row>
                <v-spacer />
                <v-col cols="5" md="3" sm="3">
                  <v-select dense solo-inverted v-model="vts.nombre" :items="vts.ventas" label="Precios" hide-details>
                  </v-select>
                </v-col>
                <v-spacer />
                <v-col cols="7" md="8" sm="8">
                  <v-text-field dense solo-inverted v-model.trim="brDescripcion" label="Buscar Productos"
                    prepend-inner-icon="search" clearable autofocus hide-details @keyup="listarProductoBuscar">
                  </v-text-field>
                </v-col>
                <v-spacer />
              </v-row>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="dpurple" @click="mostrarEstablecimientos()" v-on="on">
                    <v-icon>category</v-icon>
                  </v-btn>
                </template>
                <span>Buscar Stock Establecimientos</span>
              </v-tooltip>

              <v-btn icon @click="ocultarProductos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- Mostrar imagen de producto -->
              <v-dialog v-model="selectedZoom" max-width="400px">
                <v-avatar tile size="400">
                  <img :src="'data:image/png;base64,' + imagen_zoom" />
                </v-avatar>
              </v-dialog>
              <!-- Fin Mostrar imagen de producto -->
              <v-form v-model="isValidP">
                <v-data-table :headers="cabeceraBusquedaProductos" :items="productos" item-key="iddetalle_compra"
                  show-expand class="lgreen elevation-3" @dblclick:row="dblClickRepuesto" dense>

                  <template v-slot:item.seleccionar="{ item }">
                    <v-icon @click="agregarDetalle(item)" color="primary" :disabled="!isValidP">add_circle</v-icon>
                  </template>

                  <template v-slot:item.imagen="{ item }">
                    <template>
                      <v-avatar tile size="27">
                        <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                      </v-avatar>
                    </template>
                  </template>

                  <template v-slot:item.codigo_producto="{ item }">
                    <strong class="dpurple--text">{{ item.codigo_producto }}</strong>
                  </template>

                  <template v-slot:item.descripcion="{ item }">
                    <strong class="dpurple--text">{{ item.descripcion }}</strong> <br /> {{ item.equivalencia }}
                  </template>
                  agregarDetalle
                  <!--                   <template v-slot:item.precio_unit_venta="{ item }">
                    <div class="text-right">
                      <span>{{ item.precio_unit_venta | formatearNumero }}</span>
                    </div>
                  </template> -->

                  <template v-slot:item.precios_ventas="{ item }">
                    <div class="text-right">
                      <template v-if="vts.nombre == 'precio_unit_venta'">
                        <strong>{{ item.precio_unit_venta | formatearNumero }}</strong>
                      </template>

                      <template v-if="vts.nombre == 'precio_unit_cliente'">
                        <strong>{{ item.precio_unit_cliente | formatearNumero }}</strong>
                      </template>

                      <template v-if="vts.nombre == 'precio_unit_distribuidor'">
                        <strong>{{ item.precio_unit_distribuidor | formatearNumero }}</strong>
                      </template>
                    </div>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-row class="mt-0 mb-0">
                        <v-col cols="6" md="9" sm="4">
                          <v-text-field dense type="text" label="Descripción"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 10 && v.length <= 240) || 'Nombre debe tener entre 10 a 240 caracteres', Rules.unespacio]"
                            v-model.trim="item.descripcion_adicional" maxlength="200" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3" sm="4">
                          <v-select dense v-model="item.idtipo_afectacion" :items="tipo_afectacion"
                            label="Tipo Afectación" hide-details></v-select>
                        </v-col>
                      </v-row>
                      <template v-if="item.serie" class="mr-2">
                        <strong>SERIE:</strong>
                        {{ item.serie }}
                      </template>
                      <strong v-if="esAdministrador">Precio Compra:</strong>
                      <span v-if="esAdministrador">{{ item.idtipo_servicio == 3 ? "S/" : "$" }}
                        {{ item.precio_unit_compra.toFixed(2) | formatearNumero }}</span>

                      <template v-if="item.idtipo_servicio == 4">
                        <strong class="ml-2" v-if="esAdministrador">Precio Compra TC:</strong>

                        <span v-if="esAdministrador">S/ {{ (item.idtipo_servicio == 3 ?
                          item.precio_unit_compra : item.precio_unit_compra *
                          tc).toFixed(2) | formatearNumero
                          }}</span>
                      </template>
                      <br />
                      <strong>PROVEEDOR:</strong>
                      {{ item.proveedor.substring(0, 30) }}
                      <strong class="ml-4">FEC.INGRESO:</strong>
                      {{ item.fecha | formatearFecha }}
                      <strong>Pedido:</strong>
                      {{ item.idpedido }}
                    </td>
                  </template>
                </v-data-table>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Producto -->

        <!--Modal sucursal stock -->
        <v-dialog v-model="verEstablecimientosProductos" max-width="800px" scrollable persistent>
          <v-card>
            <v-toolbar dark dense class="dpurple mb-1">
              <v-autocomplete dense solo-inverted @keyup="selectProductos()"
                @change="listarStockEstablecimiento(idproducto), changeProductos(idproducto)"
                :search-input.sync="buscar_producto" :items="totalProductos" v-model="idproducto"
                label="Buscar Producto" :rules="[v => !!v || 'El producto es requerido']" prepend-inner-icon="category"
                required hide-details clearable>
              </v-autocomplete>
              <v-btn icon @click="ocultarEstablecimientos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-chip class="ma-1" label>
                Precio Unitario Venta: S/ {{ precioVenta | formatearNumero }}
              </v-chip>
              <v-chip class="ma-1" label>
                Cliente: S/ {{ precioCliente | formatearNumero }}
              </v-chip>
              <v-chip class="ma-1" label>
                Distribuidor: S/ {{ precioDistribuidor | formatearNumero }}
              </v-chip>
              <v-data-table :headers="cabeceraStockEstablecimiento" :items="stockEstablecimiento"
                class="lpurple elevation-3 ma-1" dense hide-default-footer>

                <template v-slot:item.stockactual="{ item }">
                  <template v-if="item.stockactual == stockMinimo">
                    <v-chip color="warning" small dark>{{ item.stockactual }}</v-chip>
                  </template>

                  <template v-else-if="item.stockactual > stockMinimo">
                    <v-chip color="success" small dark>{{ item.stockactual }}</v-chip>
                  </template>

                  <template v-else>
                    <v-chip color="error" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal sucursal stock -->

        <!-- Ventas -->
        <v-data-table :loading="loading" :headers="cabeceraListarVenta" :items="ventas" :items-per-page="15"
          item-key="idmovimiento" show-expand class="lgreen elevation-3"
          v-if="verNuevaVenta == 0 && verNuevaNotaCredito == 0" dense>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down dgreen--text">VENTAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de venta -->
              <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar()" single-line
                hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listar()" range></v-date-picker>
              </v-menu>
              <div id="container" style="position: absolute; visibility: hidden;" class="qrcode" ref="qrCodeUrl"></div>
              <v-spacer />
              <!-- Fin Formulario busqueda de venta -->
              <v-btn @click="mostrarNuevaVenta" color="primary" class="hidden-sm-and-down" small>Nuevo</v-btn>
              <v-btn @click="mostrarNuevaVenta" color="primary" class="hidden-md-and-up" fab x-small>
                <v-icon>add</v-icon>
              </v-btn>

              <!-- Modal anular Venta -->
              <v-dialog v-model="adModal" max-width="300">
                <v-card>
                  <v-card-title class="justify-center">Anular: {{ adComprobante }}</v-card-title>
                  <v-card-text v-if="idtipo_comprobante != '00'">
                    <v-form v-model="isValid">
                      <v-text-field dense class="text-xs-center" v-model.trim="motivo" label="Motivo de anulación"
                        :rules="[v => !!v || 'El motivo es requerido', v => (v && v.length >= 5 && v.length <= 30) || 'El motivo debe tener de 5 a 30 caracteres', Rules.unespacio]"
                        maxlength="30" v-uppercase hide-details></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" class="white--text" @click="anularCerrar" small>Cancelar</v-btn>
                    <v-btn color="error" class="white--text" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      @click="anular()" small>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular Venta -->

              <!-- Modal enviar email Venta -->
              <v-dialog v-model="adModalEmailCliente" max-width="500" persistent>
                <v-card>
                  <v-card-title class="text-h6">
                    {{ adComprobante }}
                    <v-spacer />
                    <v-btn icon @click="emailCerrar">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle class="justify-center">{{ cliente }}</v-card-subtitle>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-text-field dense class="text-xs-center" v-model.trim="email" label="Email"
                        :rules="[v => /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                        maxlength="60" v-lowercase hide-details></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="success" class="white--text" :loading="desabilitar"
                      :disabled="desabilitar || !isValid" @click="enviarEmail()" small>
                      <v-icon left>send</v-icon>Enviar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal enviar email Venta -->
            </v-toolbar>
          </template>
          <!-- Opciones ventas -->

          <template v-slot:item.numero="{ item }">
            <template v-if="item.idtipo_comprobante != '00'">
              <strong>{{ item.serie_comprobante + "-" + item.num_comprobante }}</strong>
            </template>

            <template v-else>
              <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
            </template>
          </template>

          <template v-slot:item.cliente="{ item }">
            <span>{{ (item.cliente).substr(0, 20) }}</span>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <strong>{{ item.importe_total | formatearNumero }}</strong>
            </div>
          </template>

          <template v-slot:item.gratuito="{ item }">
            <div class="text-right">
              <span>{{ item.gratuito | formatearNumero }}</span>
            </div>
          </template>
          <!-- Estado comprobantes -->

          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado == 'Aceptado'">
              <strong class="success--text">{{ item.estado }}</strong>
            </template>

            <template v-else-if="item.estado == 'Enviado'">
              <span class="primary--text">{{ item.estado }}</span>
            </template>

            <template v-else-if="item.estado == 'Por enviar'">
              <span class="brown--text">{{ item.estado }}</span>
            </template>

            <template v-else-if="item.estado == 'Por anular'">
              <strong class="orange--text">{{ item.estado }}</strong>
            </template>

            <template v-else-if="item.estado == 'Rechazado'">
              <strong class="error--text">{{ item.estado }}</strong>
            </template>

            <template v-else-if="item.estado == 'Anulado'">
              <strong class="error--text">{{ item.estado }}</strong>
            </template>

            <template v-else>
              <span>{{ item.estado }}</span>
            </template>
          </template>
          <!-- Fin Estado comprobantes -->
          <!-- Descarga de XML y CDR -->
          <!-- <template v-slot:item.descargas="{ item }">
            <template
              v-if="item.idtipo_comprobante == '01' || item.idtipo_comprobante == '03' || item.idtipo_comprobante == '07'">
              <template
                v-if="item.idtipo_comprobante == '01' || item.idtipo_comprobante == '03' || item.idtipo_comprobante == '07'">
                <v-chip color="cyan" class="white--text" x-small @click="descargarXml(item)">XML</v-chip>
              </template>
              <template v-if="item.idtipo_comprobante == '01' && item.estado == 'Aceptado' | item.estado == 'Anulado'
              || item.idtipo_comprobante == '07' && item.estado == 'Aceptado' | item.estado == 'Anulado'">
                <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)">CDR</v-chip>
              </template>
            </template>
          </template> -->
          <!-- Fin Descarga de XML y CDR -->
          <!-- Opciones de impresion, anular y cpe-->

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <!-- Anular Venta -->
            <template>
              <v-btn small icon v-if="item.idtipo_comprobante == '00' && item.estado == 'Creado' && item.ref_comprobante == '' && item.idusuario == usuario.idusuario ||
                item.idtipo_comprobante == '01' && item.estado == 'Aceptado' && item.ref_comprobante.substring(0, 2) != '07' && item.idusuario == usuario.idusuario ||
                item.idtipo_comprobante == '03' && item.estado == 'Aceptado' && item.ref_comprobante.substring(0, 2) != '07' && item.idusuario == usuario.idusuario ||
                item.idtipo_comprobante == '07' && item.estado == 'Aceptado' && item.idusuario == usuario.idusuario">
                <v-icon color="error" @click="anularMostrar(item), isValid = true">delete</v-icon>
              </v-btn>
            </template>
            <!-- Anular Venta -->

            <!-- Menu para mas opciones de FE -->
            <!-- <template v-if="item.idtipo_comprobante == '01' && item.estado == 'Aceptado' | item.estado == 'Creado'
            || item.idtipo_comprobante == '03' && item.estado == 'Aceptado' | item.estado == 'Creado'
            || item.idtipo_comprobante == '07' && item.estado == 'Aceptado' | item.estado == 'Creado'
            || item.idtipo_comprobante == '00' && item.estado == 'Creado'">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small icon>
                    <v-icon v-on="on" color="primary">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense> -->
            <!-- Reenviar Sunat -->
            <!-- <v-list-item link
                    v-if="item.idusuario == usuario.idusuario && item.estado == 'Creado' && item.idtipo_comprobante == '01' | item.idtipo_comprobante == '07' & item.ref_comprobante.substring(0, 2) != '03'">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text" @click="reenviarSunat(item)">Enviar a la SUNAT
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
            <!-- Fin Reenviar Sunat -->

            <!-- Nota de Credito -->
            <!-- <v-list-item link
                    v-if="item.estado == 'Aceptado' && item.idtipo_comprobante == '01' | item.idtipo_comprobante == '03' && item.ref_comprobante.substring(0, 2) != '07'">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text" @click="verDetallesNotaCredito(item)">Generar NOTA DE
                        CRÉDITO</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
            <!-- Fin Nota de Credito -->

            <!-- Convertir en Comprobante -->
            <!-- <v-list-item link
                    v-if="item.estado == 'Creado' && item.idtipo_comprobante == '00' && item.idtipo_documento == 6 && item.ref_comprobante == ''">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text" @click="convertirComprobante('01', item)">Convertir en
                        FACTURA</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link
                    v-if="item.estado == 'Creado' && item.idtipo_comprobante == '00' && item.idtipo_documento == 1 | item.idtipo_documento == 6 && item.ref_comprobante == ''">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text" @click="convertirComprobante('03', item)">Convertir en
                        BOLETA</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
            <!-- Convertir en Comprobante -->

            <!-- Guia Remision -->
            <!-- <v-list-item link
                    v-if="item.estado == 'Aceptado' | item.estado == 'Creado' && item.idtipo_comprobante == '01' | item.idtipo_comprobante == '03' | item.idtipo_comprobante == '00'">
                    <v-list-item-content>
                      <v-list-item-title class="primary--text" @click="irGuiaRemision(item)">Generar GUÍA REMISIÓN
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
            <!-- Fin Guia Remision -->

            <!-- Referencia -->
            <!-- <v-list-item v-if="item.ref_comprobante != ''">
                    <v-list-item-content>
                      <v-list-item-title class="info--text">Referencia: {{ item.ref_comprobante.substring(3, 16) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
            <!-- Fin Referencia -->
            <!-- </v-list>
              </v-menu>
            </template> -->
            <!-- Fin Menu para mas opciones de FE -->
          </template>
          <!-- Fin Opciones de impresion, anular y cpe-->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Registrado por:</strong>
              {{ item.usuario }}
              <br />
              <strong v-if="item.msj_cdr.length > 0">SUNAT:</strong>
              {{ item.msj_cdr }}
            </td>
          </template>
          <!-- Fin Opciones ventas -->
        </v-data-table>
        <!--Fin Ventas -->

        <!-- Nota de credito -->
        <v-card v-if="verNuevaNotaCredito == 1" class="elevation-3">
          <v-toolbar dark dense class="primary">
            <v-toolbar-title>Emitir Nota de Crédito</v-toolbar-title>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevaNotaCredito">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select dense v-model="idtipo_nota_cred" :items="tipo_nota_creds"
                    @change="descripcionEmision(idtipo_nota_cred)" label="Motivo de Emisión"
                    :rules="[v => !!v || 'Motivo requerido']" hide-details></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field dense v-model.trim="doc_ref" label="Ref. Comprobante" maxlength="20" v-uppercase
                    disabled hide-details></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select dense v-model="idpersona" :items="clientes" label="Cliente"
                    :rules="[v => !!v || 'El cliente es requerido']" hide-details></v-select>
                </v-col>

                <v-col cols="12">
                  <v-data-table :headers="cabeceraDetallesNC" :items="detalles" disable-pagination hide-default-footer
                    dense class="elevation-3">

                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>

                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" class="mr-2" @click="eliminarItemDetallePedido(detalles, item)"
                        :disabled="idtipo_nota_cred == '' ? true : idtipo_nota_cred != '07' ? true : false">
                        delete_forever</v-icon>
                    </template>

                    <template v-slot:item.stock_actual="{ item }">
                      <span class="font-weight-bold">{{ item.stock_actual }}</span>
                    </template>

                    <template v-slot:item.cantidad="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                        v-model.number="item.cantidad"
                        :rules="[v => (!!v && v > 0) || 'Requerido', item.cantidad <= item.cant_tope || 'Cantidad no puede ser > a stock']"
                        maxlength="6" hide-details class="center-input"
                        :disabled="idtipo_nota_cred == '' ? true : idtipo_nota_cred != '07' ? true : false"
                        @keypress="common.isNum($event)" onClick="this.select()"></v-text-field>
                    </template>

                    <template v-slot:item.precio_unit_venta="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_venta"
                        :rules="[v => !!v && v > item.precio_unit_compra || 'Requerido']" maxlength="7" hide-details
                        class="right-input" disabled @keypress="common.numDec($event, item.precio_unit_venta, 4)"
                        onClick="this.select()"></v-text-field>
                    </template>

                    <!-- <template v-slot:item.descto_item="{ item }">
					          <span class="font-weight-bold">{{item.descto_item | formatearNumero }}</span>
                    </template>-->

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span class="font-weight-bold">{{ (item.cantidad * item.precio_unit_venta) | formatearNumero
                          }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <!-- <v-col cols="10" align="end">
                      <strong>Descuento Global (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="descto_global"
                        maxlength="7"
                        @keypress="common.numDec($event, descto_global, 2)" onClick="this.select()"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                      </v-col>-->
                      <!-- <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        disabled
                        v-model="descto_item_total=common.roundOut(common.valDec(calcularDescuentoItem),2).toFixed(2)"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                      </v-col>-->
                      <!-- <v-col cols="2">
                        <v-text-field
                          dense
                          label="Placa"
                          v-model="placa"
                          v-uppercase
                          disabled
                          hide-details
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="8" align="end">
                        <strong>Gravada S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="subtotal_gravado = common.roundOut(common.valDec(importe_total) / (1 + igv), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>IGV S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="igv_gravado = common.roundOut(common.valDec(importe_total) - common.valDec(subtotal_gravado), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Importe Total S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="importe_total = common.roundOut(common.valDec(calcularTotal) - common.valDec(descto_global), 2).toFixed(2)"
                          :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gratuita S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="gratuito = common.roundOut(calcularGratuitoTotal, 2).toFixed(2)" class="right-input"
                          hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="accent" @click="enviarNotaCreditoSunatBeta()" :loading="desabilitar"
              :disabled="desabilitar || !isValid" small icon v-if="datos.cpe == 'BETA'">
              <v-icon>verified</v-icon>
            </v-btn>

            <v-btn color="error" @click="ocultarComprobanteNC()" small>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarNotaCredito()" :loading="desabilitar"
              :disabled="desabilitar || !isValid" small>
              <v-icon left>check</v-icon>Crear N/C
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Nota de credito -->

        <!-- Nueva venta -->
        <v-card v-if="verNuevaVenta == 1" class="elevation-3">
          <v-toolbar dark dense class="dgreen" flat>
            <v-toolbar-title>Nueva Venta</v-toolbar-title>
            <v-row>
              <v-spacer />
              <v-col cols="12" md="4" sm="6">
                <v-text-field dense solo-inverted @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
                  label="Código Barra" maxlength="30" append-icon="barcode_reader" hide-details>
                </v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevaVenta">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-select dense v-model="idtipo_comprobante" :items="tipo_comprobantes" label="Tipo Comprobante"
                    :rules="[v => !!v || 'Comprobante requerido']" @change="comprobanteSeleccionado(idtipo_comprobante)"
                    :disabled="no_afecta" hide-details></v-select>
                </v-col>
                <v-col cols="12" sm="9">
                  <v-autocomplete dense @keyup="selectCliente()" @change="changeCliente"
                    :search-input.sync="buscar_cliente" v-model="idpersona" :items="clientes" label="Cliente"
                    :rules="[v => !!v || 'El cliente es requerido']" clearable append-outer-icon="person_add"
                    @click:append-outer="mostrarPersona" :disabled="no_afecta" hide-details></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3" sm="3" v-if="!no_afecta">
                  <v-select dense v-model="idtipo_pago" :items="tipo_pagos" label="Tipo Pago"
                    :rules="[v => !!v || 'Tipo pago requerido']" @change="total_pago = common.valDec(importe_total)"
                    hide-details></v-select>
                </v-col>
                <v-col cols="6" md="2" sm="3">

                  <template>
                    <v-text-field dense type="text" v-model="total_pago" label="Importe TOTAL Pagado" prefix="S/"
                      maxlength="9"
                      :rules="[v => (!!v && parseFloat(v) >= parseFloat(importe_total).toFixed(2)) || 'El Importe no coincide']"
                      @keypress="common.numDec($event, total_pago, 2)" onClick="this.select()"
                      hide-details></v-text-field>
                  </template>
                </v-col>
                <v-col cols="5" md="2" sm="2">
                  <v-text-field dense
                    v-model="common.roundOut(common.valDec(total_pago) - common.valDec(importe_total), 2).toFixed(2)"
                    label="Diferencia - Vuelto" disabled hide-details></v-text-field>
                </v-col>
                <v-col cols="5" md="2" sm="3" v-if="idtipo_pago == 4 || idtipo_pago == 2 || idtipo_pago == 3">

                  <template v-if="idtipo_pago == 4">
                    <v-menu v-model="menuCalendario" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field dense v-model="fecha_vencimiento_formateada" label="Fecha Vencimiento" readonly
                          v-on="on" prepend-inner-icon="event" hide-details></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_vencimiento" @input="menuCalendario = false"
                        @change="fechaseleccionada(fecha_vencimiento)"></v-date-picker>
                    </v-menu>
                  </template>

                  <template v-if="idtipo_pago == 2 || idtipo_pago == 3">
                    <v-text-field dense v-model="num_operacion" label="Num. Operación"
                      :rules="[v => !!v || 'Numero Operación requerido']"
                      @blur.prevent="llenarCeroNumOperacion(num_operacion)" maxlength="10" hide-details></v-text-field>
                  </template>
                </v-col>
                <v-spacer />
                <v-col cols="2" md="1" sm="1">
                  <div class="text-center" v-if="!no_afecta">
                    <v-btn @click="mostrarProductos()" x-small fab color="primary">
                      <v-icon>list</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-data-table :headers="cabeceraDetallesVenta" :items="detalles" disable-pagination
                    hide-default-footer dense class="lgreen elevation-3">

                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>

                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" @click="eliminarItemDetallePedido(detalles, item)"
                        :disabled="no_afecta">delete_forever</v-icon>
                    </template>

                    <template v-slot:item.stock_actual="{ item }">
                      <div class="text-center">
                        <span class="font-weight-bold">{{ item.stock_actual }}</span>
                      </div>
                    </template>

                    <template v-slot:item.cantidad="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                        v-model.number="item.cantidad"
                        :rules="[v => (!!v && v > 0) || 'Requerido', (!no_afecta ? item.stock_actual >= item.cantidad : item.cantidad == item.cantidad) || 'No valido']"
                        maxlength="6" hide-details class="center-input" :disabled="no_afecta"
                        @keypress="common.isNum($event)" onClick="this.select()">
                      </v-text-field>
                    </template>
                    <!-- <template v-slot:item.precio_unit_venta="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_venta"
                        :rules="[v => !!v && v > item.precio_unit_compra || 'Requerido']" maxlength="9" hide-details
                        :disabled="no_afecta" @keypress="common.numDec($event, item.precio_unit_venta, 4)" onClick="this.select()"></v-text-field>
                    </template> -->

                    <template v-slot:item.precio_unit_venta="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_venta"
                        :rules="[v => !!v && v > 0 || 'Requerido']" maxlength="9" hide-details
                        :disabled="no_afecta || !esAdministrador"
                        @keypress="common.numDec($event, item.precio_unit_venta, 4)"
                        onClick="this.select()"></v-text-field>
                    </template>

                    <template v-slot:item.descto_item="{ item }">
                      <v-text-field dense type="text" v-model="item.descto_item" maxlength="9" hide-details
                        :rules="[v => !v || v <= 20 || 'Requerido']" :disabled="no_afecta"
                        @keypress="common.numDec($event, item.descto_item, 4)" onClick="this.select()"></v-text-field>
                    </template>

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span class="font-weight-bold">{{ (item.cantidad * item.precio_unit_venta - item.descto_item) |
                          formatearNumero
                          }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <!-- <v-col cols="10" align="end">
                      <strong>Descuento Global (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="descto_global"
                        maxlength="7"
                        @keypress="common.numDec($event, descto_global, 4)" onClick="this.select()"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                      </v-col>-->
                      <v-col cols="10" align="end">
                        <strong>Descuento por Item (-) S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="descto_item_total = common.roundOut(common.valDec(calcularDescuentoItem), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <!-- <v-col cols="2">
                        <v-text-field
                          dense
                          label="Placa"
                          v-model="placa"
                          maxlength="15"
                          v-uppercase
                          hide-details
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="8" align="end">
                        <strong>Gravada S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="subtotal_gravado = common.roundOut(common.valDec(importe_total) / (1 + igv), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>IGV S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="igv_gravado = common.roundOut(common.valDec(importe_total) - common.valDec(subtotal_gravado), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Importe Total S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense readonly @click="total_pago = common.valDec(importe_total)"
                          v-model="importe_total = common.roundOut(common.valDec(calcularTotal) - common.valDec(descto_global), 2).toFixed(2)"
                          :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gratuita S/</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="gratuito = common.roundOut(calcularGratuitoTotal, 2).toFixed(2)" class="right-input"
                          hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <!-- <v-btn color="accent" @click="enviarDocumentoSunatBeta()" :loading="desabilitar"
              :disabled="desabilitar || !isValid || idtipo_comprobante == '00'" small icon v-if="datos.cpe == 'BETA'">
              <v-icon>verified</v-icon>
            </v-btn> -->

            <v-btn color="error" @click="ocultarNuevaVenta()" small>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <!-- <v-btn color="primary" @click="guardarVenta()" :loading="desabilitar" :disabled="desabilitar || !isValid"
              small> -->
            <v-btn color="primary" @click="guardarVenta()" :loading="desabilitar" :disabled="desabilitar || !isValid"
              small>
              <v-icon left>check</v-icon>Crear Venta
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Nueva venta -->


        <!--Modal impresión -->
        <v-dialog v-model="comprobanteModal" :max-width="datos.formatimpresion == '1' ? '400px' : '600px'" scrollable>
          <v-card>
            <v-toolbar dark dense class="dgreen" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="emailMostrar()" v-if="idtipo_comprobante != '00'" :disabled="disableBMail">
                <v-icon>attach_email</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobanteImp()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import numeral from "numeral";
import moment from "moment";
import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";
import QRCode from "qrcodejs2";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      ventas: [],
      cabeceraListarVenta: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "GRATUITO", value: "gratuito", sortable: false },
        { text: "PAGO", value: "descrip_pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        //{ text: "", value: "descargas", sortable: false },
        { text: "OPCIÓNES", value: "opciones", sortable: false },
      ],

      cabeceraDetallesVenta: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        //{ text: "CÓDIGO", value: "codigo_producto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion_adicional", sortable: false },
        { text: "STOCK", value: "stock_actual", sortable: false },
        { text: "CANT.", value: "cantidad", align: "center", sortable: false },
        { text: "PRECIO", value: "precio_unit_venta", sortable: false },
        { text: "DESCUENTO", value: "descto_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      cabeceraDetallesNC: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion_adicional", sortable: false },
        { text: "STOCK", value: "stock_actual", sortable: false },
        { text: "CANTIDAD", value: "cantidad", sortable: false },
        { text: "PRECIO", value: "precio_unit_venta", sortable: false },
        //{ text: "DSCTO", value: "descto_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      cabeceraBusquedaProductos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "U.M.", value: "idunidad_medida", sortable: false },
        { text: "CÓDIGO", value: "codigo_producto", sortable: false },
        { text: "NOMBRE", value: "descripcion", sortable: false },
        //{ text: "EQUIVALENCIA", value: "equivalencia", sortable: false },
        { text: "CATEGORÍA", value: "categoria", sortable: false },
        { text: "MARCA", value: "marca", sortable: false, divider: true },
        { text: "STOCK", value: "stock_actual", sortable: false, align: "center", divider: true },
        { text: "PRECIO S/", value: "precios_ventas", sortable: false },
      ],

      cabeceraStockEstablecimiento: [
        { text: "CdEt", value: "idsucursal", align: "center", sortable: false },
        { text: "ESTABLECIMIENTOS", value: "establecimiento", sortable: false, divider: true },
        { text: "STOCK", value: "stockactual", align: "center", sortable: false },
      ],

      detalles: [], // Detalles pedido
      detalle_pedidos: [], // Detalles formateado
      detalleCuentasxC: [],
      productos: [],
      buscar: "",
      buscar_cliente: "",
      buscar_ubigeo: "",
      codigo_barra: "",

      idmovimiento: "",
      idpedido: 0,

      sucursal: "",
      cliente: "",
      fecha: "",
      num_documento: "",
      importe_letras: "",
      direccion: "",
      telefono: "",
      email: "",
      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",
      moneda: "",
      igv: 0,

      //placa: "",
      descto_global: 0,
      descto_item_total: 0,
      subtotal_gravado: 0,
      gratuito: 0,
      igv_gravado: 0,
      importe_total: 0,

      ref_comprobante: "",
      afecta_stock: "",

      idtipo_documento: "",
      idpersona: "",
      clientes: [],

      total_pago: 0,
      num_operacion: "",
      // Ventas
      verProductos: 0,
      brDescripcion: "",
      selectedZoom: false,
      imagen_zoom: "",
      tc: 0,

      verEstablecimientosProductos: 0,
      buscar_producto: "",
      idproducto: "",
      totalProductos: [],

      stockEstablecimiento: [],
      precioVenta: "",
      precioCliente: "",
      precioDistribuidor: "",
      stockMinimo: "",

      verNuevaVenta: 0,
      idtipo_pago: "",
      tipo_pagos: [],
      idtipo_comprobante: "", // 00=Recibo Interno, 01=Factura, 03=Boleta de Venta
      tipo_comprobantes: [],
      iddetalle_comprobante_sucursal: "",

      fecha_vencimiento_formateada: "",
      fecha_vencimiento: moment().format("YYYY-MM-DD"),
      fecha_actual: moment().format("YYYY-MM-DD"),

      menuCalendario: false,
      // Fin Ventas

      comprobanteModal: 0,
      verNuevaNotaCredito: 0,

      desabilitar: false,
      isValid: true, // Validamos
      isValidP: true,

      disableBMail: true,

      adModal: 0,
      adComprobante: 0,
      motivo: "",

      no_afecta: false,

      adModalEmailCliente: 0,

      stringPdf: "",
      blobPdf: "",

      doc_ref: "", // Numero de comprobante FE referencia
      cod_doc_ref: "", // Codigo de tipo documento FE referencia

      idtipo_nota_cred: "",
      tipo_nota_creds: [],

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona
      tipo_afectacion: [],

      vts: {
        nombre: "precio_unit_venta",
        ventas: [
          { text: "Venta", value: "precio_unit_venta" },
          { text: "Cliente", value: "precio_unit_cliente" },
          { text: "Distribuidor", value: "precio_unit_distribuidor" },
        ],
      },

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "10") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].cantidad) * me.common.valDec(me.detalles[i].precio_unit_venta) - me.common.valDec(me.detalles[i].descto_item), 2);
        }
      }
      return resultado;
    },

    calcularGratuitoTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "31") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].cantidad) * me.common.valDec(me.detalles[i].precio_unit_venta) - me.common.valDec(me.detalles[i].descto_item), 2);
        }
      }
      return resultado;
    },

    calcularDescuentoItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(me.common.valDec(me.detalles[i].descto_item), 2);
      }
      return resultado;
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobanteImp();
    },
    verProductos(val) {
      val || this.ocultarProductos();
    },
    brDescripcion(newVal) {
      if (newVal === null) {
        this.brDescripcion = "";
      }
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.fechaseleccionada(this.fecha_vencimiento);
    this.listar();
    this.select();
  },

  methods: {
    ...mapActions(["snackBar"]),

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_vencimiento_formateada = ddmmyy;
    },

    //#region VENTAS
    listar(printid) {
      let me = this;
      me.loading = true;

      let adVenta = me.$route.params.adventa;
      let dePedido = me.$route.params.depedido; // true
      let deidpersona = me.$route.params.deidpersona;
      let deidpedido = me.$route.params.deidpedido;

      if (adVenta === true) {
        me.verNuevaVenta = 1;
        me.$route.params.adventa = false;
      }

      if (dePedido == true) {
        me.idpersona = deidpersona;
        me.selectCliente(deidpersona);
        me.idpedido = deidpedido;
        me.listarDetallePedidos(deidpedido);
        me.verNuevaVenta = 1;
        me.$route.params.depedido = false;
      }

      if (me.buscar == "") {
        axios.get("api/Movimientos/ListarVenta", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.ventas = response.data;
            me.loading = false;
            if (printid) {
              me.cargaComprobante(printid);
            }
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Movimientos/ListarVentaTotal", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.ventas = response.data;
            me.loading = false;
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    irGuiaRemision(item) {
      this.$router.push({
        name: "guias_remision",
        params: {
          venta: true,
          idmovimientoventa: item.idmovimiento,
          idpersonaventa: item.idpersona,
          idpedidoventa: item.idpedido,
          numero: item.serie_comprobante + "-" + item.num_comprobante,
          idmotivotraslado: "01",
        },
      });
    },

    reenviarSunat(item) {
      let me = this;
      if (item.idtipo_comprobante == "01") {
        axios.put("api/Movimientos/ReenviarVentaSunat/" + item.idmovimiento)

          .then(function (response) {
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      } else if (item.idtipo_comprobante == "07") {
        axios.put("api/Movimientos/ReenviarNotaCreditoSunat/" + item.idmovimiento)
          .then(function (response) {
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      } else {
        // console.log("error"); // No es Factura ni Nota de credito
      }
    },

    enviarDocumentoSunatBeta() {
      let me = this;
      if (me.idtipo_comprobante == "00") { // 0='Recibo Interno'
        return me.snackBar({ cl: "warning", msg: "El Comprobante no es valido para enviar a Sunat Beta." });
      }
      me.desabilitar = true;
      me.detalle_pedidos = []; // Limpiamos array para llenarlo
      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para venta

      axios.post("api/Movimientos/EnviarSunatBeta", {
        // Para actualizar detalle_documento_sucursal
        iddetalle_comprobante_sucursal: me.iddetalle_comprobante_sucursal,
        subtotal_gravado: parseFloat(me.subtotal_gravado),
        gratuito: parseFloat(me.gratuito),
        igv_gravado: parseFloat(me.igv_gravado),
        importe_total: parseFloat(me.importe_total),
        //placa: me.placa,
        idtipo_comprobante: me.idtipo_comprobante, // boleta = 01 o factura = 03
        // Datos del Cliente Receptor
        idpersona: me.idpersona, // NRO_DOCUMENTO_CLIENTE, RAZON_SOCIAL_CLIENTE, DIRECCION_CLIENTE
        idtipo_documento: me.idtipo_documento, // 1 = dni, 6 = ruc
        // Detalle de nuestra FE
        detalle_pedidos: me.detalle_pedidos,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data }); // Error de Pc
        });
    },

    guardarVenta() {
      let me = this;

      // Validamos si el detalle de pedido esta vacio
      if (me.detalles.length <= 0) { return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." }); }
      if (me.detalles.length > 30) { return me.snackBar({ cl: "warning", msg: "El detalle solo acepta hasta 30 items." }); }
      // Validamos que la fecha de vencimiento sea mayor a la fecha actual
      if (me.idtipo_pago == 4 && Date.parse(me.fecha_vencimiento) <= Date.parse(me.fecha_actual)) {
        return me.snackBar({ cl: "warning", msg: "Modificar la fecha de vencimiento." });
      }
      // Si total_pago es menor al importe_total rechazamos
      if (parseFloat(me.total_pago) - parseFloat(me.importe_total) < 0) { return me.snackBar({ cl: "warning", msg: "Verifique el importe." }); }

      me.desabilitar = true;
      me.detalle_pedidos = [];
      me.convertirDetalleCPE();

      axios.post("api/Movimientos/CrearVenta", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idpersona: me.idpersona, //FE
        idusuario: parseInt(me.usuario.idusuario),
        idpedido: me.idpedido,
        num_operacion: me.idtipo_pago == 2 ? me.num_operacion : me.idtipo_pago == 3 ? me.num_operacion : "",
        idtipo_comprobante: me.idtipo_comprobante, // boleta = 01 o factura = 03
        igv: parseFloat(me.igv),
        descto_global: 0,
        descto_item_total: parseFloat(me.descto_item_total),
        otros_item_total: 0,
        seguro: 0,
        inafecto: 0,
        exonerado: 0,
        gratuito: parseFloat(me.gratuito),
        subtotal_gravado: parseFloat(me.subtotal_gravado),
        igv_gravado: parseFloat(me.igv_gravado),
        importe_total: parseFloat(me.importe_total),
        efectivo: me.idtipo_pago == 1 ? parseFloat(me.total_pago) : 0,
        //placa: me.placa,
        detalle_pedidos: me.detalle_pedidos,
        fecha_vencimiento: me.fecha_vencimiento, // Fecha pago credito
        idtipo_pago: me.no_afecta == true ? 6 : me.idtipo_pago, // 6 = Contado - Ninguno
        afecta_stock: me.no_afecta == true ? "N" : "S",
        ref_comprobante: me.no_afecta == true ? me.cod_doc_ref + "-" + me.doc_ref : "",
        idrecibo_interno: me.no_afecta == true ? me.idmovimiento : 0,
        // Comprobantes ultima_serie, ultimo_numero
        iddetalle_comprobante_sucursal: me.iddetalle_comprobante_sucursal,
      })
        .then(function (response) {
          me.ocultarNuevaVenta();
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          var printid = response.data.idmovimiento;
          me.listar(printid);
          me.limpiar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listar(); // Si sale error se actualiza pedido deberia ir a ventas
        });
    },

    convertirComprobante(num, item) {
      let me = this;
      me.idmovimiento = item.idmovimiento; // RI
      me.no_afecta = true;
      me.doc_ref = item.serie_comprobante + "-" + item.num_comprobante;
      me.cod_doc_ref = item.idtipo_comprobante; // boleta = 03 o factura = 01
      me.idtipo_comprobante = num;
      me.comprobanteSeleccionado(num);
      me.idpersona = item.idpersona;
      me.total_pago = item.importe_total.toFixed(2);
      me.selectCliente(item.idpersona);
      me.listarDetallePedidos(item.idpedido);
      me.verNuevaVenta = 1;
    },

    mostrarNuevaVenta() {
      this.verNuevaVenta = 1;
      // Mostramos por defecto Recibo
      this.idtipo_comprobante = "00";
      this.comprobanteSeleccionado(this.idtipo_comprobante);
    },

    ocultarNuevaVenta() {
      this.verNuevaVenta = 0;
      this.desabilitar = false; // Habilitamos boton guardar venta
      this.limpiar();
    },

    convertirDetalleCPE() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_pedidos.push({
          idproducto: me.detalles[i].idproducto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          idunidad_medida: me.detalles[i].idunidad_medida,
          cantidad: parseInt(me.detalles[i].cantidad),
          // Validamos si es Gravado
          valor_unit_venta: me.detalles[i].idtipo_afectacion == "10" ? me.common.roundOut(parseFloat(me.detalles[i].precio_unit_venta) / (1 + me.igv), 4) : 0,
          precio_unit_venta: parseFloat(me.detalles[i].precio_unit_venta),
          descto_item: parseFloat(me.detalles[i].descto_item == "" ? 0 : me.detalles[i].descto_item),
          //Validamos si es Gravado
          valor_venta: me.detalles[i].idtipo_afectacion == "10" ? me.common.roundOut((parseInt(me.detalles[i].cantidad) * parseFloat(me.detalles[i].precio_unit_venta)) / (1 + me.igv), 2) : 0,
          precio_venta: me.common.roundOut(parseInt(me.detalles[i].cantidad) * parseFloat(me.detalles[i].precio_unit_venta), 2),
          igv: me.igv * 100, // FE 18%
          codigo_producto: me.detalles[i].codigo_producto, // FE
          descripcion_adicional: me.detalles[i].descripcion_adicional,
          idprod_sunat: me.detalles[i].idprod_sunat, // FE
          idtipo_afectacion: me.detalles[i].idtipo_afectacion,
        });
      }
    },

    descargarXml(item) {
      let me = this;

      axios.get("api/Movimientos/DescargarXml/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", me.datos.ruc + "-" + item.idtipo_comprobante + "-" + item.serie_comprobante + "-" + item.num_comprobante + ".xml");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    descargarCdr(item) {
      let me = this;

      axios.get("api/Movimientos/DescargarCdr/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "R-" + me.datos.ruc + "-" + item.idtipo_comprobante + "-" + item.serie_comprobante + "-" + item.num_comprobante + ".xml");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    anular() {
      let me = this;
      me.desabilitar = true;

      if (me.idtipo_comprobante == "00") {
        axios.put("api/Movimientos/AnularVenta/" + me.idmovimiento + "/" + me.usuario.idsucursal + "/" + me.usuario.idusuario)
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.anularCerrar();
            me.listar();
          })
          .catch(function (error) {
            //console.log(error);
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      } else if (me.idtipo_comprobante == "03" || me.idtipo_comprobante == "07" && me.adComprobante.substring(0, 1) == "B") {
        axios.put("api/Movimientos/CrearResumenBaja", {
          idmovimiento: me.idmovimiento,
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          motivo: me.motivo,
        })
          .then(function (response) {
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
            me.anularCerrar();
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      } else if (me.idtipo_comprobante == "01" || me.idtipo_comprobante == "07" && me.adComprobante.substring(0, 1) == "F") {
        axios.put("api/Movimientos/CrearComunicacionBaja", {
          idmovimiento: me.idmovimiento,
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          motivo: me.motivo,
        })
          .then(function (response) {
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
            me.anularCerrar();
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      } else {
        me.snackBar({ cl: "error", msg: "Hubo un error" });
      }
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.idmovimiento = item.idmovimiento;
      this.adComprobante = item.serie_comprobante + "-" + item.num_comprobante;
      this.idtipo_comprobante = item.idtipo_comprobante;
    },

    anularCerrar() {
      this.adModal = 0;
      this.idmovimiento = "";
      this.idtipo_comprobante = "";
      this.desabilitar = false;
      this.motivo = "";
      this.limpiar();
    },

    llenarCeroNumOperacion(num_operacion) {
      this.num_operacion = ("0000000000" + num_operacion).slice(-10);
    },
    //#endregion VENTAS

    //#region PRODUCTO
    buscarCodigoBarra() {
      let me = this;

      axios.get("api/Pedidos/BuscarCodigoBarraVenta/" + parseInt(me.usuario.idsucursal) + "/" + me.codigo_barra)
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
          me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
          me.codigo_barra = "";
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "warning", msg: "No hay registro de compra para el producto." });
          me.codigo_barra = "";
        });
    },

    listarProductoBuscar() {
      let me = this;

      axios.get("api/Pedidos/ListarProductoVenta", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
        },
      })
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    dblClickRepuesto(event, { item }) {
      this.agregarDetalle(item);
    },

    agregarDetalle(data = []) {
      let me = this;
      // Buscar el índice del detalle existente con el mismo iddetalle_compra
      const indiceExistente = me.detalles.findIndex(detalle => detalle.iddetalle_compra === data["iddetalle_compra"]);

      if (indiceExistente !== -1) {
        // Si el detalle ya existe, sumar la cantidad
        me.detalles[indiceExistente].cantidad += 1;
      } else {
        me.detalles.push({
          idproducto: data["idproducto"],
          iddetalle_compra: data["iddetalle_compra"],
          codigo_producto: data["codigo_producto"], // FE
          //categoria: data["categoria"],
          //marca: data["marca"],
          idtipo_afectacion: data["idtipo_afectacion"],
          descripcion_adicional: data["descripcion_adicional"],
          stock_actual: data["stock_actual"],
          idunidad_medida: data["idunidad_medida"],
          cantidad: 1,
          valor_unit_venta: 0,
          precio_unit_venta: data[me.vts.nombre], // Capturamos el precio seleccionado
          descto_item: "",
          valor_venta: 0,
          precio_venta: 0,
          idprod_sunat: data["idprod_sunat"],
          precio_unit_compra: me.common.roundOut((data["idtipo_servicio"] == 3 ? data["precio_unit_compra"] : data["precio_unit_compra"] * me.tc), 4) // > compra
        });
      }
    },

    mostrarProductos() {
      this.verProductos = 1;
    },

    ocultarProductos() {
      this.verProductos = 0;
      this.productos = [];
      this.imagen_zoom = "";
      this.brDescripcion = "";
      // limpiamos variables de buscar producto en establecimientos
      this.idproducto = "";
      this.buscar_producto = "";
      this.precioVenta = 0;
      this.precioCliente = 0;
      this.precioDistribuidor = 0;
      //this.totalProductos = [];
      this.stockEstablecimiento = [];
      this.stockMinimo = 1;
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },

    changeProductos(valor) {
      this.precioVenta = 0;
      this.precioCliente = 0;
      this.precioDistribuidor = 0;
      this.stockMinimo = 1;

      if (valor != null) {
        const selectId = this.totalProductos.find(
          (elemento) => elemento.value === valor
        );
        this.precioVenta = selectId.precioVenta;
        this.precioCliente = selectId.precioCliente;
        this.precioDistribuidor = selectId.precioDistribuidor;
        this.stockMinimo = selectId.stockMinimo;
      }
    },

    // Buscar productos
    selectProductos() {
      let me = this;
      var productosArray = []; // Limpiamos para cargar nuevamente

      axios.get("api/Productos/SelectBuscar", {
        params: {
          buscar: me.buscar_producto == "" ? "-" : me.buscar_producto == null ? "-" : me.buscar_producto
        },
      })
        .then(function (response) {
          productosArray = response.data;
          productosArray.map(function (x) {
            me.totalProductos.push({ text: x.codigo_producto + " " + x.descripcion + " " + x.categoria + " " + x.marca, value: x.idproducto, precioVenta: x.precio_unit_venta, precioCliente: x.precio_unit_cliente, precioDistribuidor: x.precio_unit_distribuidor, stockMinimo: x.stock_minimo });
          });
        })
        .then((data) => { })
        .catch(function (error) {
          //console.log(error);
        });

    },

    listarStockEstablecimiento(id) {
      let me = this;

      axios.get("api/Productos/ListarStockEstablecimiento", {
        params: {
          idproducto: id
        },
      })
        .then(function (response) {
          me.stockEstablecimiento = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    mostrarEstablecimientos() {
      this.verEstablecimientosProductos = 1;
    },
    ocultarEstablecimientos() {
      this.verEstablecimientosProductos = 0;
    },
    //#endregion PRODUCTO

    //#region SELECT
    descripcionEmision(valor) {
      if (valor != null) {
        const selectId = this.tipo_nota_creds.find(
          (elemento) => elemento.value === valor
        );
        this.motivo = selectId.text;
      }
    },

    comprobanteSeleccionado(idtipo_comprobante) {
      if (idtipo_comprobante != null) {
        const selectId = this.tipo_comprobantes.find(
          (elemento) => elemento.value === idtipo_comprobante
        );
        this.iddetalle_comprobante_sucursal =
          selectId.iddetalle_comprobante_sucursal;
      }
      if (this.idtipo_comprobante == "01") {
        if (this.idtipo_documento == 6) {
          //console.log("comprobante valido para factura");
        } else {
          this.clientes = [];
          this.idpersona = "";
        }
      }
      // Si es Recibo Interno igv = 0 de lo contrario igv=0.18
      this.idtipo_comprobante == "00" // Recibo Interno
        ? (this.igv = 0)
        : (this.igv = parseFloat(this.datos.igv));
    },

    changeCliente(valor) {
      if (valor != null) {
        const selectId = this.clientes.find(
          (elemento) => elemento.value === valor
        );
        this.idtipo_documento = selectId.tipo_documento;
        //console.log(this.idtipo_documento) // mostrar que tipo documento de cliente 1 ó 6
      }
    },

    selectCliente(id) {
      let me = this;

      var clientesArray = [];

      if (id) {
        axios.get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => {
            me.changeCliente(id);
          })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Personas/SelectPersonasFiltro", {
          params: {
            buscar:
              me.buscar_cliente == "" ? "-" : me.buscar_cliente == null ? "-" : me.buscar_cliente,
            tipo_doc: me.idtipo_comprobante == "" ? "-" : me.idtipo_comprobante == null ? "-" : me.idtipo_comprobante == "01" ? "RUC" : "-",
          },
        })
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => {
            me.changeCliente(id); // En observacion
          })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscar_ubigeo == ""
                ? "-"
                : me.buscar_ubigeo == null
                  ? "-"
                  : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;

      var tipo_comprobantesArray = [];
      axios.get("api/Detalle_Comprobante_Sucursales/SelectDetalleComprobanteSucursales/" + parseInt(me.usuario.idsucursal))
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            me.tipo_comprobantes.push({
              text: x.comprobante,
              value: x.idtipo_comprobante,
              iddetalle_comprobante_sucursal: x.iddetalle_comprobante_sucursal,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_documentosArray = [];
      axios.get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_afectacionArray = [];
      axios.get("api/Tipo_Afectacion/Select")
        .then(function (response) {
          tipo_afectacionArray = response.data;
          tipo_afectacionArray.map(function (x) {
            me.tipo_afectacion.push({
              text: x.descripcion,
              value: x.idtipo_afectacion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_nota_credsArray = [];
      axios.get("api/Tipo_Nota_Creds/Select")
        .then(function (response) {
          tipo_nota_credsArray = response.data;
          tipo_nota_credsArray.map(function (x) {
            me.tipo_nota_creds.push({
              text: x.descripcion,
              value: x.idtipo_nota_cred,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_pagosArray = [];
      axios.get("api/Tipo_Pagos/SelectVenta")
        .then(function (response) {
          tipo_pagosArray = response.data;
          tipo_pagosArray.map(function (x) {
            me.tipo_pagos.push({
              text: x.descripcion,
              value: x.idtipo_pago,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion SELECT

    //#region NOTA CREDITO
    enviarNotaCreditoSunatBeta() {
      let me = this;

      me.desabilitar = true;
      me.detalle_pedidos = []; // Limpiamos array para llenarlo
      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para venta

      axios.post("api/Movimientos/EnviarNotaCreditoBeta", {
        // Enviamos a probar
        idsucursal: parseInt(me.usuario.idsucursal),
        subtotal_gravado: parseFloat(me.subtotal_gravado),
        gratuito: parseFloat(me.gratuito),
        igv_gravado: parseFloat(me.igv_gravado),
        importe_total: parseFloat(me.importe_total),
        //placa: me.placa,
        //NRO_COMPROBANTE: "FF01-" + "1", // FF01-1 Nota de credito
        // Datos comprobante de referencia para nota de credito
        Nro_otr_comprobante: me.doc_ref,
        Cod_otr_comprobante: me.cod_doc_ref,
        Cod_tipo_motivo: me.idtipo_nota_cred,
        Descripcion_motivo: me.removeAccents(me.motivo.toUpperCase()),
        // Datos del Cliente Receptor
        idpersona: me.idpersona, // NRO_DOCUMENTO_CLIENTE, RAZON_SOCIAL_CLIENTE, DIRECCION_CLIENTE
        idtipo_documento: me.idtipo_documento, // 1 = dni, 6 = ruc
        // Detalle de nuestra FE
        detalle_pedidos: me.detalle_pedidos,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    guardarNotaCredito() {
      let me = this;

      me.desabilitar = true; // Desabilitamos boton guardar
      me.detalle_pedidos = []; // Limpiamos array para llenarlo

      if (me.idtipo_nota_cred == "07" && me.afecta_stock == "N") {
        me.desabilitar = false;
        return me.snackBar({ cl: "warning", msg: "El motivo de emisión no esta permitido para las ventas con origen RECIBO." });
      }

      me.convertirDetalleCPE(); // Actualizamos datos en detalle_pedidos para NC

      axios.post("api/Movimientos/CrearNotaCredito", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idpersona: me.idpersona, //FE
        idusuario: parseInt(me.usuario.idusuario),
        igv: parseFloat(me.igv),
        subtotal_gravado: parseFloat(me.subtotal_gravado),
        gratuito: parseFloat(me.gratuito),
        igv_gravado: parseFloat(me.igv_gravado),
        importe_total: parseFloat(me.importe_total),
        //placa: me.placa,
        idreferencia: me.idmovimiento,
        ref_comprobante: me.cod_doc_ref + "-" + me.doc_ref,
        idtipo_nota_cred: me.idtipo_nota_cred,
        motivo: me.removeAccents(me.motivo.toUpperCase()),
        idtipo_pago: me.afecta_stock == "N" ? 6 : 1, // 6 = Contado - Ninguno
        afecta_stock: me.afecta_stock,
        detalle_pedidos: me.detalle_pedidos,
      })
        .then(function (response) {
          me.ocultarComprobanteNC();
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    // Eliminar detalle pedido NC y Venta
    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    verDetallesNotaCredito(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.igv = parseFloat(item.igv);
      this.idmovimiento = item.idmovimiento; // Venta
      this.doc_ref = item.serie_comprobante + "-" + item.num_comprobante;
      this.cod_doc_ref = item.idtipo_comprobante; // boleta = 03 o factura = 01
      this.idpersona = item.idpersona;
      this.afecta_stock = item.afecta_stock;
      //this.placa = item.placa;
      this.selectCliente(item.idpersona);
      this.listarDetallePedidos(item.idpedido);
      this.verNuevaNotaCredito = 1;
    },

    ocultarComprobanteNC() {
      this.verNuevaNotaCredito = 0;
      this.desabilitar = false; // Habilitamos boton guardar NC
      this.limpiar();
    },
    //#endregion NOTA CREDITO

    //#region IMPRESION
    // Para descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    cargaComprobante(id) {
      this.limpiar();

      const selectId = this.ventas.find(
        (elemento) => elemento.idmovimiento === id
      );

      this.idmovimiento = selectId.idmovimiento;
      this.idtipo_pago = selectId.idtipo_pago;
      if (this.idtipo_pago == 4) { this.listarDetalleCuentasxC(this.idmovimiento); }
      this.sucursal = selectId.sucursal;
      this.nomusuario = selectId.usuario;
      this.idtipo_comprobante = selectId.idtipo_comprobante;
      this.comprobante = selectId.comprobante;
      this.serie_comprobante = selectId.serie_comprobante;
      this.num_comprobante = selectId.num_comprobante;
      this.subtotal_gravado = selectId.subtotal_gravado;
      this.descto_item_total = selectId.descto_item_total;
      this.gratuito = selectId.gratuito;
      this.igv_gravado = selectId.igv_gravado;
      this.importe_total = selectId.importe_total;
      this.importe_letras = selectId.importe_letras;
      //this.placa = selectId.placa;
      this.hash_cpe = selectId.hash_cpe;
      this.ref_comprobante = selectId.ref_comprobante;
      this.motivo = selectId.motivo;
      this.idpedido = selectId.idpedido;
      this.listarDetallePedidos(selectId.idpedido);
      // Cliente
      this.idtipo_documento = selectId.idtipo_documento;
      this.cliente = selectId.cliente;
      this.num_documento = selectId.num_documento;
      this.direccion = selectId.direccion;
      this.nom_ubigeo = selectId.nom_ubigeo;
      this.telefono = selectId.telefono;
      this.email = selectId.email;
      this.fecha = selectId.fecha;
      this.descrip_pago = selectId.descrip_pago;
      this.igv = selectId.igv;
      this.moneda = selectId.moneda;
      this.comprobanteModal = 1;
    },

    mostrarComprobante(item) {
      this.cargaComprobante(item.idmovimiento);
    },

    // Lista detalle de cuenta x cobrar
    async listarDetalleCuentasxC(id) {
      let me = this;
      me.detalleCuentasxC = [];
      const response = await axios.get("api/Detalle_Cuentas/ListarDetallesCxC/" + id)
        .then(function (response) {
          me.detalleCuentasxC = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    ocultarComprobanteImp() {
      this.comprobanteModal = 0;
      this.disableBMail = true;
      this.detalleCuentasxC = [];
      // Limpiamos div carga de qr
      document.getElementById("container").innerHTML = "";
      this.limpiar();
    },
    //#endregion IMPRESION

    //#region det. VENTA, NOTA DE CREDITO, IMPRESION
    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio_unit_venta
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              idunidad_medida: x.idunidad_medida,
              codigo_producto: x.codigo_producto,
              descripcion_adicional: x.descripcion_adicional,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              cantidad: x.cantidad,
              cant_tope: x.cantidad,
              precio_unit_venta: x.precio_unit_venta.toFixed(2),
              descto_item: x.descto_item,
              precio_venta: x.precio_venta,
              idprod_sunat: x.idprod_sunat,
              idtipo_afectacion: x.idtipo_afectacion,
              precio_unit_compra: me.common.roundOut((x.idtipo_servicio == 3 ? x.precio_unit_compra : x.precio_unit_compra * me.tc), 2) // > compra
            });
          });
          if (me.datos.formatimpresion == "1") {
            me.crearPDFTK();
          } else if (me.datos.formatimpresion == "2") {
            me.crearPDFA4();
          } else {
            console.log("No existe un formato definido de impresión.");
          }
          // console.log(me.detalles);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion det. VENTA, NOTA DE CREDITO, IMPRESION

    //#region EMAIL
    enviarEmail() {
      let me = this;
      me.desabilitar = true; // Desabilitamos boton enviar

      axios.put("api/Movimientos/EnviarEmail", {
        idmovimiento: me.idmovimiento,
        cliente: me.cliente,
        email: me.email,
      })
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          me.emailCerrar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    emailMostrar() {
      this.adModalEmailCliente = 1;
      this.adComprobante = this.serie_comprobante + "-" + this.num_comprobante;
    },

    emailCerrar() {
      this.adModalEmailCliente = 0;
      this.desabilitar = false;
    },
    //#endregion EMAIL

    //#region PERSONA
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;
      // https://apisperu.com
      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo);
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
          });
      } else if (me.idtipo_documento_pers == 6) {
        axios.post("api/Personas/Padron", {
          ruc: me.num_documento,
        })
          .then(function (response) {
            me.desabilitarBuscar = false;
            me.idubigeo = response.data.ubigeo;
            me.selectUbigeo(me.idubigeo);
            me.nombre = response.data.razon;
            me.direccion =
              response.data.direccion == null
                ? ""
                : response.data.direccion.trim();
            me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
          })
          .catch(function (error) {
            me.desabilitarBuscar = false;
            me.nombre = "";
            me.direccion = "";
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      axios.post("api/Personas/Crear", {
        tipo_persona: "Cliente",
        nombre: me.nombre,
        idtipo_documento: me.idtipo_documento_pers,
        num_documento: me.num_documento,
        idubigeo: me.idubigeo,
        direccion: me.direccion,
        telefono: me.telefono,
        email: me.email,
      })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectCliente(me.idpersona);
          me.desabilitarPer = false;
          me.snackBar({ cl: "success", msg: response.data.mensaje });
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      //this.idtipo_comprobante = "";
      this.limpiarPersona();
    },
    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    //#endregion PERSONA

    limpiar() {
      this.igv = parseFloat(this.datos.igv); // requerido
      this.idmovimiento = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.importe_letras = "";
      this.adComprobante = "";
      this.nomusuario = "";
      //this.placa = "";
      this.hash_cpe = "";
      this.idtipo_nota_cred = "";
      this.doc_ref = "";
      this.cod_doc_ref = "";
      this.clientes = [];
      this.subtotal_gravado = 0;
      this.descto_item_total = 0;
      this.gratuito = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;
      this.detalles = [];
      this.detalle_pedidos = [];
      this.idpedido = 0;
      // Cliente
      this.cliente = "";
      this.num_documento = "";
      this.direccion = "";
      this.telefono = "";
      this.email = "";
      this.fecha = "";
      this.moneda = "";
      this.ref_comprobante = "";
      this.motivo = "";
      this.afecta_stock = "";
      this.desabilitar = false;
      // Venta
      this.codigo_barra = ""; // Buscar codigo de barras
      this.idtipo_comprobante = "";
      this.iddetalle_comprobante_sucursal = "";
      this.idpersona = "";
      this.idtipo_documento = "";
      this.idtipo_pago = "";
      this.total_pago = 0;
      this.num_operacion = "";
      this.fecha_vencimiento = moment().format("YYYY-MM-DD"); // Cronograma de pagos
      this.fechaseleccionada(this.fecha_vencimiento); // Cargamos fecha actual a fecha_vencimiento y fecha_vencimiento_formateada
      this.no_afecta = false;
      this.stringPdf = "";
      this.blobPdf = "";
    },

    crearPDFTK() {
      var doc = new jsPDF("p", "mm", [78, 270]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;
      var subir = "";

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
        subir = false;
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase() + " ELECTRONICA";
        // QR
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text: this.datos.ruc + "|" + this.idtipo_comprobante + "|" + this.serie_comprobante + "|" + this.num_comprobante + "|" + this.igv_gravado + "|" + this.importe_total + "|" + moment(this.fecha).format("DD/MM/YYYY") + "|" + this.idtipo_documento + "|" + this.num_documento,
          width: 80,
          height: 80,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
        subir = true;
        // Fin QR
      }

      var columns = [
        { header: "DESCRIPCIÓN", dataKey: "descripcion_adicional" },
        { header: "CANT.", dataKey: "cantidad" },
        { header: "P/U", dataKey: "precio_unit_venta" },
        { header: "DTO", dataKey: "descto_item" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          descripcion_adicional: this.detalles[i].descripcion_adicional,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format("###,##0.00"),
          descto_item: numeral(this.detalles[i].descto_item).format("###,##0.00"),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          if (this.idtipo_comprobante == "00") {
            foot.push(
              [
                {
                  content: "DSCTO ITEM S/ ",
                  colSpan: 4,
                },
                numeral(this.descto_item_total).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 4,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]);
          } else {
            foot.push(
              [
                {
                  content: "GRAVADA S/ ",
                  colSpan: 3,
                },
                numeral(this.subtotal_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "GRATUITA S/ ",
                  colSpan: 3,
                },
                numeral(this.gratuito).format("###,##0.00"),
              ],
              [
                {
                  content: "IGV S/ ",
                  colSpan: 3,
                },
                numeral(this.igv_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 3,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]
            );
          }
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", centro / 2, 4, 36, 18);

      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 5, right: 5, top: 24 },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
          halign: "center",
        },

        body: [
          { columna0: this.datos.ruc, },
          { columna0: this.datos.dirsucursal, },
          { columna0: this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal, },
          { columna0: this.datos.mailsucursal },
          { columna0: this.datos.telsucursal },
          { columna0: nomcomprob },
          { columna0: this.serie_comprobante + "-" + this.num_comprobante },
        ],
        columns: [{ header: this.datos.empresa, dataKey: "columna0" }],
        // En negrita las filas
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold";
          }
          if (data.row.index === 5) {
            data.cell.styles.fontStyle = "bold";
          }
          if (data.row.index === 6) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 2,
        theme: "plain",
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        margin: { left: 5, right: 5 },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
        },
        body: [
          //{ columna0: "VENDEDOR: " + this.nomusuario },
          { columna0: "FECHA DE EMISIÓN: " + moment(this.fecha).format("DD/MM/YYYY"), },
          //{ columna0: "FECHA DE VENCIMIENTO: " + (this.idtipo_pago == 4 ? moment(this.detalleCuentasxC[0].fecha_vencimiento).format("DD/MM/YYYY") : moment(this.fecha).format("DD/MM/YYYY")), },
          { columna0: "CLIENTE: " + this.cliente },
          { columna0: "RUC/DNI: " + this.num_documento },
          { columna0: "DIRECCIÓN: " + this.direccion + " - " + this.nom_ubigeo, },
          //{ columna0: "PLACA: " + this.placa },
        ],
      });

      // DETALLE DE VENTA
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1,
        theme: "plain",
        columns,
        body,
        foot,
        margin: { left: 5, right: 5 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.3,
          fontSize: 6,
          halign: "center",
        },
        bodyStyles: { cellPadding: 0.5, textColor: 0, fontSize: 6 },
        footStyles: {
          cellPadding: 0.3,
          fontStyle: "normal",
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          descripcion_adicional: { cellWidth: "auto" },
          cantidad: { cellWidth: 10, halign: "center" },
          precio_unit_venta: { cellWidth: 12, halign: "right" },
          descto_item: { cellWidth: 10, halign: "right" },
          subtotal: { cellWidth: 14, halign: "right" },
        },
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);

      let finalY = doc.lastAutoTable.finalY;
      doc.text("SON: " + this.importe_letras, 5, finalY + 3);
      // Factura y/o Boleta
      if (
        this.idtipo_comprobante == "00" ||
        this.idtipo_comprobante == "01" ||
        this.idtipo_comprobante == "03"
      ) {
        doc.text("FORMA DE PAGO: " + this.descrip_pago, 5, finalY + 7);
        // Cuadro para firma 
        doc.setFontSize(5);
        doc.text("// TICKET ADMINISTRATIVO //", 5, finalY + 11);
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 12,
          theme: "grid",
          margin: { left: 5, right: 5 },

          headStyles: {
            cellPadding: 5,
            fillColor: [255, 255, 255],
            lineColor: 0,
            lineWidth: 0.2,
          },
          columnStyles: {
            columna0: { cellWidth: 34 },
            columna1: { cellWidth: 34 },
          },
          columns: [
            { header: "-", dataKey: "columna0" },
            { header: "-", dataKey: "columna1" },
          ],
        });

        doc.text("ENTREGADO", 15, finalY + 28);
        doc.text("CANCELADO", 50, finalY + 28);

      }
      // Nota credito
      if (this.idtipo_comprobante == "07") {
        doc.text("MOTIVO: " + this.motivo, 5, finalY + 7);
        doc.text("DOC. AFECTADO: " + this.ref_comprobante.substring(3, 16), 5, finalY + 11);
      }

      // Referencia de comprobante convertido
      if (this.idtipo_comprobante == "00" && this.ref_comprobante != "") {
        doc.text("REFERENCIA: " + this.ref_comprobante.substring(3, 16), 5, finalY + 15);
      }

      if (imageQR) {
        doc.text("Representacion Impresa de la " + this.comprobante + " Electronica", centro, finalY + 19, "center");
        doc.text("consulte su comprobante en SUNAT", centro, finalY + 23, "center");
        doc.addImage(imageQR, "JPEG", 26.5, finalY + 26, 25, 25);
        //doc.text("GRACIAS POR SU COMPRA", centro, finalY + 47, "center");
        doc.text("RESUMEN", centro, finalY + 50, "center");
        doc.setFontSize(6);
        doc.text(this.hash_cpe, centro, finalY + 54, "center");
      }

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      subir ? this.subirPdf() : null; // Subimos pdf blob a nuetra api
      /* Crear PDF venta */
    },

    crearPDFA4() {
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;
      var subir = "";

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
        subir = false;
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase() + " ELECTRONICA";
        // QR
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text: this.datos.ruc + "|" + this.idtipo_comprobante + "|" + this.serie_comprobante + "|" + this.num_comprobante + "|" + this.igv_gravado + "|" + this.importe_total + "|" + moment(this.fecha).format("DD/MM/YYYY") + "|" + this.idtipo_documento + "|" + this.num_documento,
          width: 80,
          height: 80,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
        subir = true;
        // Fin QR
      }

      var columns = [
        { header: "CANT.", dataKey: "cantidad" },
        { header: "U.M.", dataKey: "idunidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion_adicional" },
        { header: "P/U", dataKey: "precio_unit_venta" },
        { header: "DSCTO", dataKey: "descto_item" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          idunidad_medida: this.detalles[i].idunidad_medida,
          descripcion_adicional: this.detalles[i].descripcion_adicional,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format("###,##0.00"),
          descto_item: numeral(this.detalles[i].descto_item).format("###,##0.00"),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          if (this.idtipo_comprobante == "00") {
            foot.push(
              [
                {
                  content: "DSCTO ITEM S/ ",
                  colSpan: 5,
                },
                numeral(this.descto_item_total).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 5,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]);
          } else {
            foot.push(
              [
                {
                  content: "GRAVADA S/ ",
                  colSpan: 4,
                },
                numeral(this.subtotal_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "GRATUITA S/ ",
                  colSpan: 4,
                },
                numeral(this.gratuito).format("###,##0.00"),
              ],
              [
                {
                  content: "IGV S/ ",
                  colSpan: 4,
                },
                numeral(this.igv_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 4,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]
            );
          }
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", 15, 19, 36, 18);

      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 50, right: 70, top: 17 },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 9,
          textColor: 0,
          halign: "center",
        },
        body: [
          { columna0: this.datos.dirsucursal + " " + this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal, },
          { columna0: "Email: " + this.datos.mailsucursal },
          { columna0: "Atención Cliente: " + this.datos.telsucursal },
        ],
        columns: [{ header: this.datos.empresa, dataKey: "columna0" }],
      });

      // COMPROBANTE DE PAGO
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.roundedRect(141, 17, 54, 18, 1, 1);
      //doc.rect(141, 17, 54, 18);
      doc.text("RUC " + this.datos.ruc, 168, 22, "center");
      doc.text(nomcomprob, 168, 27, "center");
      doc.text(this.serie_comprobante + "-" + this.num_comprobante, 168, 32, "center");

      // Mostramos QR para el CPE
      if (imageQR) {
        doc.addImage(imageQR, "JPEG", 170, doc.lastAutoTable.finalY + 2, 25, 25);
      }

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 5,
        theme: "plain",
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        margin: { left: 15, right: 45 },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
        },
        body: [
          //{ columna0: "VENDEDOR: " + this.nomusuario },
          { columna0: "FECHA DE EMISIÓN: " + moment(this.fecha).format("DD/MM/YYYY"), },
          //{ columna0: "FECHA DE VENCIMIENTO: " + (this.idtipo_pago == 4 ? moment(this.detalleCuentasxC[0].fecha_vencimiento).format("DD/MM/YYYY") : moment(this.fecha).format("DD/MM/YYYY")), },
          { columna0: "CLIENTE: " + this.cliente },
          { columna0: "RUC/DNI: " + this.num_documento },
          { columna0: "DIRECCIÓN: " + this.direccion + " - " + this.nom_ubigeo, },
          //{ columna0: "PLACA: " + this.placa },
        ],
      });

      // DETALLE DE VENTA
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 4,
        theme: "grid",
        columns,
        body,
        foot,
        margin: { left: 15, right: 15 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 8,
          halign: "center",
          fillColor: [227, 228, 229],
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        footStyles: {
          cellPadding: 0.3,
          fontStyle: "normal",
          fillColor: 255,
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          cantidad: { cellWidth: 15, halign: "center" },
          idunidad_medida: { cellWidth: 15, halign: "center" },
          descripcion_adicional: { cellWidth: "auto" },
          precio_unit_venta: { cellWidth: 20, halign: "right" },
          descto_item: { cellWidth: 15, halign: "right" },
          subtotal: { cellWidth: 20, halign: "right" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.text("SON: " + this.importe_letras, 15, finalY + 4);
      // Factura y/o Boleta
      if (this.idtipo_comprobante == "00" || this.idtipo_comprobante == "01" || this.idtipo_comprobante == "03") {
        doc.text("FORMA DE PAGO: " + this.descrip_pago, 15, finalY + 8);
      }
      // Nota credito
      if (this.idtipo_comprobante == "07") {
        doc.text("MOTIVO: " + this.motivo, 15, finalY + 8);
        doc.text("DOC. AFECTADO: " + this.ref_comprobante.substring(3, 16), 15, finalY + 12);
      }

      // Referencia de comprobante convertido
      if (this.idtipo_comprobante == "00" && this.ref_comprobante != "") {
        doc.text("REFERENCIA: " + this.ref_comprobante.substring(3, 16), 15, finalY + 16);
      }

      // Si existe QR mostramos mensaje de CPE
      if (imageQR) {
        doc.setFont("helvetica", "normal");
        doc.text("Representacion Impresa de la " + this.comprobante + " Electronica. " + "Resumen " + this.hash_cpe, centro, finalY + 18, "center");
        doc.text("consulte su comprobante en SUNAT", centro, finalY + 22, "center");
      }

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      subir ? this.subirPdf() : null; // Subimos pdf blob a nuetra api
      /* Crear PDF venta */
    },

    subirPdf() {
      let me = this;

      // Código para editar usuario
      let formData = new FormData();
      formData.append("file", me.blobPdf);
      formData.append("nomcomprobante", me.datos.ruc + "-" + me.idtipo_comprobante + "-" + me.serie_comprobante + "-" + me.num_comprobante);
      axios.post("api/Movimientos/SubirFile", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          me.disableBMail = false; // se habilita boton para enviar por mail
          //console.log(response);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>